#resumeContainer {
    display: flex; 
    justify-content: center; 
    height: 800px;
}


@media only screen and (max-width: 480px) {
    /* Styles for older mobile devices */

    #resumeContainer {
        display: flex; 
        justify-content: center; 
        height: 350px;
    }
    
  }