#contactContainer {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    height: 100vh
}

@media only screen and (max-width: 480px) {
    /* Styles for older mobile devices */
    #contactContainer {
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        height: 430px
    }
  }