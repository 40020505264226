#tableCont {
    margin-top: 75px;
    border: 1px solid black;
    border-radius: 20px;
    width: 80%;
    display: flex;
    flex-direction: row;
    height: 500px;
    padding: 20px;
}

#column1 {
    border-right: 1px solid black;
}

.columns {
    width: 50%;
    align-items: center;
}

.columnTitle {
    font-family: 'Courier New', Courier, monospace;
    font-size: 22px;
    font-weight: bold;
}

.descPara {
    line-height: 25px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    background: linear-gradient(to right, #0b79c9, #e202de);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

@media only screen and (max-width: 480px) {
    /* Styles for older mobile devices */
    .descPara {
        line-height: 15px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 10px;
    }

    #tableCont {
        margin-top: 0px;
        border: 1px solid black;
        border-radius: 10px;
        width: 90%;
        display: flex;
        flex-direction: column;
        height: 700px;
        padding: 20px;
    }

    #column1 {
        border-right: 0px;
        border-bottom: 1px solid black;
    }

    .columns {
        width: 100%;
        align-items: center;
    }

    .container {
        margin-top: 20px;
    }
}