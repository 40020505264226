#footerItems {
    list-style: none;
    display: flex;
    flex-direction: row;
}

#footerItems li {
    margin-left: 25px;
    margin-right: 25px;
    font-size: 17px;
    font-weight: bold;
    font-style: italic;
}


.footerCont {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    margin-bottom: 50px;
}

.footer {
    display: flex;
    justify-content: center;
}

#footerItems img {
    margin: 10px 30px;
}

@media only screen and (max-width: 480px) {
    /* Styles for older mobile devices */
    .footerCont {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 2.5rem;
        margin-bottom: 50px;
        margin-top: 80px;
    }

    #footerItems img {
        margin: 10px 10px;
    }
  }