.formCont {
    margin-top: 100px;
    width: 70%;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.9);
    border: 2px solid black;
    border-radius: 15px;
    padding: 30px;
}

.formCont h3 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bolder;
    font-size: 25px;
    background: linear-gradient(to right, #0b79c9, #e202de);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.formCont ul {
    list-style: none;
    color: white;
    font-weight: bold;
}

.formCont a {
    text-decoration: none;
    color: inherit;
}

@media only screen and (max-width: 480px) {
    /* Styles for older mobile devices */
    .formCont {
        margin-top: 30px;
        width: 90%;
        height: 300px;
        background-color: rgba(0, 0, 0, 0.9);
        border: 2px solid black;
        border-radius: 15px;
        padding: 30px;
    }

    .formCont ul {
        list-style: none;
        color: white;
        font-weight: bold;
        padding-right: 35px;
    }
  }